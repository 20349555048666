import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { SessionState, SessionStatus, postAction } from "../utils";

interface LoginProps {
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
}

export function Login({ sessionState, setSessionState }: LoginProps) {
	const [password, setPassword] = useState("");
	return (
		<Box
			sx={{
				margin: "auto",
				marginTop: "20px",
				maxWidth: "400px",
				padding: "20px",
				boxShadow: 24,
				background: (theme) => theme.palette.background.paper,
				overflow: "auto",
				borderRadius: "5px",
			}}
		>
			{sessionState.status === SessionStatus.EXPIRED && (
				<Typography sx={{ color: "white", margin: "20px", justifyContent: "center", display: "flex" }}>
					Session expired, please log in and try again:
				</Typography>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<TextField label="Password" type="password" onChange={(e) => setPassword(e.target.value)}></TextField>
				<Button
					variant="outlined"
					sx={{ marginLeft: "5px" }}
					onClick={() =>
						postAction(
							{
								action: "login",
								password,
							},
							sessionState,
							setSessionState,
							(res: any) => {
								localStorage.setItem("token", res.token);
								setSessionState({
									token: res.token,
									status: SessionStatus.LOGGED_IN,
								});
							},
							(e) => console.error(e)
						)
					}
				>
					Login
				</Button>
			</Box>
		</Box>
	);
}
