import { Add, Delete, Email, Save } from "@mui/icons-material";
import { Box, Divider, FormControl, FormHelperText, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { JSX } from "react/jsx-runtime";
import { CommonData, compareDates, LayoutField, SettingsObject } from "../utils";

const EDITED_COLOR = "#442244";

type CaseFieldsProps = {
	settings: SettingsObject;
	commonData: CommonData;
	synced?: boolean;
	caseData: { [id: string]: string };
	saveKeyPressed?: boolean;
	setCommonData?: (arg0: CommonData) => void;
	setCaseData?: (arg0: { [id: string]: string }) => void;
	onSubmit?: (arg0: CommonData, arg1: { [id: string]: string }) => void;
	declareEdited?: (arg0: string, arg1: boolean) => void;
};

function createForm(
	template: (LayoutField | null)[],
	newCaseData: { [id: string]: string },
	caseData: { [id: string]: string },
	setNewCaseData: (arg0: { [id: string]: string }) => void,
	synced?: boolean,
	suffix: string = ""
) {
	return template.map((layoutField, index) => {
		if (layoutField === null) {
			return <Divider key={index} />;
		}
		switch (layoutField.type) {
			case "phone":
			case "text":
				return (
					<FormControl sx={{ m: 1, minWidth: 300 }} key={`${layoutField.label}$${suffix}`}>
						<TextField
							value={newCaseData[`${layoutField.label}$${suffix}`]}
							sx={
								!synced && (caseData[`${layoutField.label}$${suffix}`] || "") !== (newCaseData[`${layoutField.label}$${suffix}`] || "")
									? { background: EDITED_COLOR }
									: {}
							}
							onChange={(event) =>
								setNewCaseData({
									...newCaseData,
									[`${layoutField.label}$${suffix}`]: event.target.value,
								})
							}
						></TextField>
						<FormHelperText>{layoutField.label}</FormHelperText>
					</FormControl>
				);
			case "email":
				return (
					<FormControl sx={{ m: 1, minWidth: 300 }} key={`${layoutField.label}$${suffix}`}>
						<TextField
							value={newCaseData[`${layoutField.label}$${suffix}`]}
							sx={
								!synced && (caseData[`${layoutField.label}$${suffix}`] || "") !== (newCaseData[`${layoutField.label}$${suffix}`] || "")
									? { background: EDITED_COLOR }
									: {}
							}
							onChange={(event) =>
								setNewCaseData({
									...newCaseData,
									[`${layoutField.label}$${suffix}`]: event.target.value,
								})
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip title="Send Email">
											<IconButton href={`mailto:${newCaseData[`${layoutField.label}$${suffix}`]}`}>
												<Email />
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
						<FormHelperText>{layoutField.label}</FormHelperText>
					</FormControl>
				);
			case "choice":
				return (
					<FormControl sx={{ m: 1, minWidth: 300 }} key={`${layoutField.label}$${suffix}`}>
						<Select
							value={newCaseData[`${layoutField.label}$${suffix}`]}
							sx={
								!synced && (caseData[`${layoutField.label}$${suffix}`] || "") !== (newCaseData[`${layoutField.label}$${suffix}`] || "")
									? { background: EDITED_COLOR }
									: {}
							}
							onChange={(event) =>
								setNewCaseData({
									...newCaseData,
									[`${layoutField.label}$${suffix}`]: event.target.value,
								})
							}
						>
							{layoutField.choices?.map((choice) => (
								<MenuItem value={choice}>{choice}</MenuItem>
							))}
						</Select>
						<FormHelperText>{layoutField.label}</FormHelperText>
					</FormControl>
				);
			case "subform": {
				let subformElements: JSX.Element[] = [];
				const count = Number(newCaseData[`${layoutField.label}_count$${suffix}`] || layoutField?.minimum || "0");
				for (let i = 0; i < count; i++) {
					subformElements.push(
						<Box sx={{ border: (theme) => `1px solid ${theme.palette.grey[800]}`, m: 1, borderRadius: "8px", p: 1 }}>
							<Tooltip title="Delete">
								<IconButton
									disabled={layoutField?.minimum === undefined ? false : count <= layoutField.minimum}
									sx={{ m: 1 }}
									size="small"
									onClick={() => {
										let tempCaseData = { ...newCaseData };
										for (let j = i + 1; j < count; j++) {
											const prevSuffix = `$${suffix}_${j - 1}`;
											const currentSuffix = `$${suffix}_${j}`;
											Object.keys(newCaseData)
												.filter((label) =>
													layoutField.template
														?.filter((field) => field !== null)
														.map((field) => `${field.label}${currentSuffix}`)
														.includes(label)
												)
												.map((label) => [label, label.split("$")[0] + prevSuffix])
												.forEach(([current, previous]) => (tempCaseData[previous] = tempCaseData[current]));
										}

										Object.keys(newCaseData)
											.filter((label) =>
												layoutField.template
													?.filter((field) => field !== null)
													.map((field) => `${field.label}$${suffix}_${count - 1}`)
													.includes(label)
											)
											.forEach((label) => (tempCaseData[label] = ""));
										setNewCaseData({
											...tempCaseData,
											[`${layoutField.label}_count$${suffix}`]: String(count - 1),
										});
									}}
								>
									<Delete />
								</IconButton>
							</Tooltip>
							{createForm(layoutField.template || [], newCaseData, caseData, setNewCaseData, synced, `${suffix}_${i}`)}
						</Box>
					);
				}
				return (
					<>
						<Typography sx={{ color: (theme) => theme.palette.text.secondary, m: 1 }}>{layoutField.label}</Typography>
						<Box>{subformElements}</Box>
						<Tooltip title="Add">
							<IconButton
								sx={{ m: 1, backgroundColor: (theme) => theme.palette.primary.main }}
								size="small"
								onClick={() => {
									setNewCaseData({
										...newCaseData,
										[`${layoutField.label}_count$${suffix}`]: String(count + 1),
									});
								}}
							>
								<Add />
							</IconButton>
						</Tooltip>
					</>
				);
			}
		}
	});
}

export default function CaseFields({
	settings: { caseTypes, attorneyList, accountTypes, drmOtherTypes, staffList, statusTypes, layoutByCaseType },
	commonData,
	caseData,
	synced,
	saveKeyPressed,
	setCommonData,
	setCaseData,
	onSubmit,
	declareEdited,
}: CaseFieldsProps) {
	const [newCommonData, setNewCommonData] = useState<CommonData>({
		...commonData,
	});
	const [newCaseData, setNewCaseData] = useState<{ [id: string]: string }>(caseData);
	const { caseType, attorney, accountType, DRMOther, staff, caseOpenDate, status, caseNumber, lastUpdate } = newCommonData;

	useEffect(() => {
		if (synced && setCaseData) {
			setCaseData(newCaseData);
		}
	}, [newCaseData]);

	useEffect(() => {
		if (synced && setCommonData) {
			setCommonData(newCommonData);
		}
	}, [newCommonData]);

	useEffect(() => {
		if (synced && layoutByCaseType[commonData.caseType]?.overrides) {
			const newEntries: { [id: string]: string } = {};
			Object.entries(layoutByCaseType[commonData.caseType].overrides || {}).forEach(([key, value]) => {
				newEntries[key] = value;
			});
			setNewCommonData({
				...commonData,
				...newEntries,
			});
		}
	}, [commonData.caseType]);

	const isEdited =
		caseType !== commonData.caseType ||
		attorney !== commonData.attorney ||
		accountType !== commonData.accountType ||
		DRMOther !== commonData.DRMOther ||
		staff !== commonData.staff ||
		!compareDates(lastUpdate, commonData.lastUpdate) ||
		!compareDates(caseOpenDate, commonData.caseOpenDate) ||
		status !== commonData.status ||
		caseNumber !== commonData.caseNumber ||
		Object.keys(newCaseData).filter((label) => newCaseData[label] !== (caseData[label] || "")).length > 0;

	useEffect(() => {
		if (!synced && declareEdited) {
			declareEdited(`info`, isEdited);
		}
	}, [isEdited]);

	useEffect(() => {
		if (saveKeyPressed === true) {
			if (commonData.caseOpenDate === null || !isEdited) {
				return;
			}
			if (!onSubmit || synced) {
				return;
			}
			onSubmit(newCommonData, newCaseData);
		}
	}, [saveKeyPressed]);

	return (
		<>
			{!synced && (
				<Box sx={{ position: "absolute", top: "15px", right: "63px" }}>
					<Tooltip title="Save Changes">
						<IconButton
							size="large"
							sx={{ position: "fixed", background: (theme) => theme.palette.primary.main }}
							disabled={commonData.caseOpenDate === null || !isEdited}
							onClick={() => onSubmit && onSubmit(newCommonData, newCaseData)}
						>
							<Save />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<Select
					value={caseType}
					sx={!synced && caseType !== commonData.caseType ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, caseType: event.target.value });
					}}
				>
					{!caseTypes.includes(caseType) && <MenuItem value={caseType}>{caseType}</MenuItem>}
					{caseTypes.map((v) => (
						<MenuItem value={v}>{v}</MenuItem>
					))}
				</Select>
				<FormHelperText>Case Type</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<Select
					value={attorney}
					sx={!synced && attorney !== commonData.attorney ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, attorney: event.target.value });
					}}
				>
					{!attorneyList.includes(attorney) && <MenuItem value={attorney}>{attorney}</MenuItem>}
					{attorneyList.map((v) => (
						<MenuItem value={v}>{v}</MenuItem>
					))}
				</Select>
				<FormHelperText>Attorney Assigned</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<Select
					value={accountType}
					sx={!synced && accountType !== commonData.accountType ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, accountType: event.target.value });
					}}
				>
					{!accountTypes.includes(accountType) && <MenuItem value={accountType}>{accountType}</MenuItem>}
					{accountTypes.map((v) => (
						<MenuItem value={v}>{v}</MenuItem>
					))}
				</Select>
				<FormHelperText>Account Type</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<Select
					value={DRMOther}
					sx={!synced && DRMOther !== commonData.DRMOther ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, DRMOther: event.target.value });
					}}
				>
					{!drmOtherTypes.includes(DRMOther) && <MenuItem value={DRMOther}>{DRMOther}</MenuItem>}
					{drmOtherTypes.map((v) => (
						<MenuItem value={v}>{v}</MenuItem>
					))}
				</Select>
				<FormHelperText>DRM/Other</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<Select
					value={staff}
					sx={!synced && staff !== commonData.staff ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, staff: event.target.value });
					}}
				>
					{!staffList.includes(staff) && <MenuItem value={staff}>{staff}</MenuItem>}
					{staffList.map((v) => (
						<MenuItem value={v}>{v}</MenuItem>
					))}
				</Select>
				<FormHelperText>Staff Assigned</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }} error={caseOpenDate === null}>
				<DatePicker
					views={["day", "month", "year"]}
					value={caseOpenDate}
					sx={!synced && !compareDates(caseOpenDate, commonData.caseOpenDate) ? { background: EDITED_COLOR } : {}}
					onChange={(v) => {
						setNewCommonData({ ...newCommonData, caseOpenDate: v });
					}}
				/>
				<FormHelperText>Case Opened (Required)</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<DatePicker
					views={["day", "month", "year"]}
					value={lastUpdate}
					sx={!synced && !compareDates(lastUpdate, commonData.lastUpdate) ? { background: EDITED_COLOR } : {}}
					onChange={(v) => {
						setNewCommonData({ ...newCommonData, lastUpdate: v });
					}}
				/>
				<FormHelperText>Last Update</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<Select
					value={status}
					sx={!synced && status !== commonData.status ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, status: event.target.value });
					}}
				>
					{!statusTypes.includes(status) && <MenuItem value={status}>{status}</MenuItem>}
					{statusTypes.map((v) => (
						<MenuItem value={v}>{v}</MenuItem>
					))}
				</Select>
				<FormHelperText>Case Status</FormHelperText>
			</FormControl>
			<FormControl sx={{ m: 1, minWidth: 300 }}>
				<TextField
					value={caseNumber}
					sx={!synced && caseNumber !== commonData.caseNumber ? { background: EDITED_COLOR } : {}}
					onChange={(event) => {
						setNewCommonData({ ...newCommonData, caseNumber: event.target.value });
					}}
				/>
				<FormHelperText>Case Number</FormHelperText>
			</FormControl>
			<Divider />
			{layoutByCaseType[caseType] && createForm(layoutByCaseType[caseType].template, newCaseData, caseData, setNewCaseData, synced)}
		</>
	);
}
