import { Add, FileCopy, FormatListNumbered, Logout } from "@mui/icons-material";
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Logo from "./assets/hbs-logo-2.svg";
import { AddCase } from "./components/AddCase";
import { Exemptions } from "./components/Exemptions";
import { Gallery } from "./components/Gallery";
import { Login } from "./components/Login";
import { SessionState, SessionStatus, getSettings, postAction } from "./utils";

function getTabFromPathname(pathname: string) {
	switch (pathname) {
		case "/":
			return 0;
		case "/add-case":
			return 1;
		case "/exemptions":
			return 2;
		default:
			return -1;
	}
}

function App() {
	const tab = getTabFromPathname(window.location.pathname);
	const [drawerOpen, setDrawerOpen] = useState(false);

	const [saveKeyPressed, setSaveKeyPressed] = useState<boolean>(false);

	useEffect(() => {
		document.addEventListener("keyup", (e: KeyboardEvent) => {
			if (e.ctrlKey && e.key === "s") {
				e.preventDefault();
				setSaveKeyPressed(false);
			}
		});
		document.addEventListener("keydown", (e: KeyboardEvent) => {
			if (e.ctrlKey && e.key === "s") {
				e.preventDefault();
				setSaveKeyPressed(true);
			}
		});
	}, []);

	const [sessionState, setSessionState] = useState<SessionState>(
		localStorage.getItem("token") !== null
			? {
					status: SessionStatus.LOGGED_IN,
					token: localStorage.getItem("token") as string,
			  }
			: {
					status: SessionStatus.LOGGED_OUT,
			  }
	);

	const settingsQuery = useQuery({
		queryKey: ["settings"],
		queryFn: () => getSettings(sessionState, setSessionState),
		enabled: false,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			settingsQuery.refetch();
		}
	}, [sessionState]);

	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 700;

	if (sessionState.status === SessionStatus.LOGGED_OUT) {
		return (
			<Box className="App" sx={{ display: "flex" }}>
				<Modal open={true}>
					<Login sessionState={sessionState} setSessionState={setSessionState} />
				</Modal>
			</Box>
		);
	}

	return (
		<Box className="App" sx={{ display: "flex" }}>
			<Box sx={{ width: 200 }}>
				<Drawer
					onClose={() => {
						if (isMobile) {
							setDrawerOpen(false);
						}
					}}
					variant={isMobile ? "temporary" : "permanent"}
					open={isMobile ? drawerOpen : true}
					sx={{ display: "block", xs: "none", "& .MuiDrawer-paper": { boxSizing: "border-box", width: 200, background: "#005593", border: "none" } }}
				>
					<div style={{ padding: "25px" }}>
						<img src={Logo} />
					</div>
					<Divider variant="middle" />
					<List>
						<ListItem disablePadding>
							<ListItemButton onClick={() => (window.location.pathname = "/")}>
								<ListItemIcon>
									<FormatListNumbered />
								</ListItemIcon>
								<ListItemText primary="Gallery" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton onClick={() => (window.location.pathname = "/add-case")}>
								<ListItemIcon>
									<Add />
								</ListItemIcon>
								<ListItemText primary="Add Case" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton onClick={() => (window.location.pathname = "/exemptions")}>
								<ListItemIcon>
									<FileCopy />
								</ListItemIcon>
								<ListItemText primary="Exemptions" />
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton
								onClick={() =>
									postAction(
										{
											action: "logout",
										},
										sessionState,
										setSessionState,
										() => {
											localStorage.removeItem("token");
											setSessionState({
												token: undefined,
												status: SessionStatus.LOGGED_OUT,
											});
										}
									)
								}
							>
								<ListItemIcon>
									<Logout />
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</ListItemButton>
						</ListItem>
					</List>
				</Drawer>
			</Box>
			{settingsQuery.isFetched && settingsQuery.data && (
				<Box sx={{ flexGrow: 1, p: 1, width: isMobile ? {} : { sm: `calc(100% - 240px)` } }}>
					{tab === -1 && (
						<Box
							sx={{
								m: 2,
							}}
						>
							<Typography variant="h2" color="white">
								404
							</Typography>
							<Typography variant="h6" color="white">
								Page not found
							</Typography>
						</Box>
					)}
					{tab === 0 && (
						<Gallery
							saveKeyPressed={saveKeyPressed}
							openDrawer={() => setDrawerOpen(true)}
							isMobile={isMobile}
							settings={settingsQuery.data}
							sessionState={sessionState}
							setSessionState={setSessionState}
							refetchSettings={() => settingsQuery.refetch()}
						/>
					)}
					{tab === 1 && (
						<AddCase
							openDrawer={() => setDrawerOpen(true)}
							isMobile={isMobile}
							settings={settingsQuery.data}
							sessionState={sessionState}
							setSessionState={setSessionState}
							onClose={() => (window.location.pathname = "/")}
						/>
					)}
					{tab === 2 && (
						<Exemptions
							saveKeyPressed={saveKeyPressed}
							settings={settingsQuery.data}
							openDrawer={() => setDrawerOpen(true)}
							isMobile={isMobile}
							sessionState={sessionState}
							setSessionState={setSessionState}
						/>
					)}
				</Box>
			)}
			<Modal open={sessionState.status === SessionStatus.EXPIRED}>
				<Login sessionState={sessionState} setSessionState={setSessionState} />
			</Modal>
		</Box>
	);
}

export default App;
