import { GridSortItem } from "@mui/x-data-grid";
import { Document, Page, PDFViewer, StyleSheet, View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { sortCaseListData } from "../utils";

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		fontSize: "10px",
		paddingTop: "45px",
		paddingBottom: "45px",
	},
	titleContainer: {
		flexDirection: "row",
	},
	title: {
		fontSize: "14px",
		padding: "2px",
		width: "50%",
	},
	date: {
		padding: "2px",
		width: "50%",
		textAlign: "right",
		verticalAlign: "sub",
	},
	table: {
		marginTop: "8px",
	},
	headerRow: {
		flexDirection: "row",
		backgroundColor: "#666",
		color: "#fff",
	},
	row: {
		flexDirection: "row",
		borderBottom: "1px dotted black",
	},
	clientNameRow: {
		width: "22%",
		padding: "4px",
	},
	attorneyRow: {
		width: "15%",
		padding: "4px",
	},
	statusRow: {
		width: "8%",
		padding: "4px",
	},
	caseTypeRow: {
		width: "20%",
		padding: "4px",
	},
	staffRow: {
		width: "15%",
		padding: "4px",
	},
	deadlineRow: {
		width: "20%",
		padding: "4px",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
});

const PDFDocument = ({ data, deadlines }: { data: object[]; deadlines: { [id: string]: string } }) => {
	return (
		<Document>
			<Page size="LETTER" style={styles.page}>
				<View style={styles.section}>
					<View style={styles.titleContainer}>
						<Text style={styles.title}>Case List</Text>
						<Text style={styles.date}>AS OF {dayjs(Date.now()).format("MM/DD/YYYY hh:mm A")}</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.headerRow}>
							<Text style={styles.clientNameRow}>Client Name</Text>
							<Text style={styles.attorneyRow}>Attorney</Text>
							<Text style={styles.statusRow}>Status</Text>
							<Text style={styles.caseTypeRow}>Case Type</Text>
							<Text style={styles.staffRow}>Staff Assigned</Text>
							<Text style={styles.deadlineRow}>Upcoming Deadline</Text>
						</View>
						{data.map((datum: any) => (
							<View style={styles.row} wrap={false}>
								<Text style={styles.clientNameRow}>{datum["Client Name$"] || datum["Client Name$_0"]}</Text>
								<Text style={styles.attorneyRow}>{datum["attorney"]}</Text>
								<Text style={styles.statusRow}>{datum["status"]}</Text>
								<Text style={styles.caseTypeRow}>{datum["caseType"]}</Text>
								<Text style={styles.staffRow}>{datum["staff"]}</Text>
								<Text style={styles.deadlineRow}>
									{deadlines[datum["_id"]] === undefined ? (
										<Text style={{ color: "#aaa" }}>No Upcoming Deadlines</Text>
									) : (
										dayjs(deadlines[datum["_id"]]).format("MM/DD/YYYY hh:mm A")
									)}
								</Text>
							</View>
						))}
					</View>
				</View>
			</Page>
		</Document>
	);
};

export const GalleryPDF = ({ sortModel, data, deadlines }: { sortModel: GridSortItem[]; data: object[]; deadlines: { [id: string]: string } }) => (
	<PDFViewer
		style={{
			width: "100%",
			height: "100%",
		}}
	>
		<PDFDocument data={sortCaseListData(sortModel, data, deadlines)} deadlines={deadlines} />
	</PDFViewer>
);
