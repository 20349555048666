import { Archive, Close, Print, Restore, Save, Unarchive } from "@mui/icons-material";
import {
	Alert,
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	FormControl,
	IconButton,
	LinearProgress,
	Modal,
	Tab,
	Tabs,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { CommonData, QueryStatus, SessionState, SessionStatus, SettingsObject, compareDates, getCase, postAction } from "../utils";
import CaseFields from "./CaseFields";
import { DetailsGrid, DetailsType } from "./DetailsGrid";
import { DetailsPDF } from "./DetailsPDF";

interface DetailsProps {
	detailsModal: string | null;
	settings: SettingsObject;
	onClose: () => void;
	id: string;
	sessionState: SessionState;
	saveKeyPressed: boolean;
	setSessionState: (arg0: SessionState) => void;
}

export function Details({
	detailsModal,
	settings,
	settings: { caseTypes, drmOtherTypes, statusTypes },
	id,
	onClose,
	sessionState,
	saveKeyPressed,
	setSessionState,
}: DetailsProps) {
	const [tab, setTab] = useState(0);
	const [deleteModal, setDeleteModal] = useState<string | null>(null);
	const [openPrintModal, setOpenPrintModal] = useState(false);

	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);

	const [commonData, setCommonData] = useState<CommonData>({
		caseType: caseTypes[0],
		attorney: "",
		accountType: "",
		DRMOther: drmOtherTypes[0],
		staff: "",
		caseOpenDate: null,
		lastUpdate: null,
		status: statusTypes[0],
		caseNumber: "",
	});

	const [lastUpdateMain, setLastUpdateMain] = useState<Dayjs | null>(null);

	const [caseData, setCaseData] = useState<{ [id: string]: string }>({});

	const [editedCategories, setEditedCategories] = useState<string[]>([]);
	const [editedDialog, setEditedDialog] = useState<null | "tab0" | "tab1" | "exit">(null);

	const [isArchived, setIsArchived] = useState(false);

	const caseQuery = useQuery({
		queryKey: ["case", id],
		queryFn: () => getCase(id, sessionState, setSessionState),
		enabled: false,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			if (id !== "") {
				caseQuery.refetch();
			}
		}
		setLastUpdateMain(commonData.lastUpdate);
	}, [sessionState, tab]);

	useEffect(() => {
		if (id !== "") {
			caseQuery.refetch();
		}
		setTab(0);
	}, [detailsModal]);

	useEffect(() => {
		if (!compareDates(lastUpdateMain, commonData.lastUpdate)) {
			setEditedCategories([...editedCategories, "lastUpdateMain"]);
		} else {
			setEditedCategories(editedCategories.filter((cat) => cat !== "lastUpdateMain"));
		}
	}, [lastUpdateMain]);

	useEffect(() => {
		const tempCommonData = {
			caseType: caseQuery.data?.caseType,
			attorney: caseQuery.data?.attorney,
			accountType: caseQuery.data?.accountType,
			DRMOther: caseQuery.data?.DRMOther,
			staff: caseQuery.data?.staff,
			status: caseQuery.data?.status,
			caseNumber: caseQuery.data?.caseNumber,
			caseOpenDate: dayjs(caseQuery.data?.["caseOpenDate"]),
			lastUpdate:
				caseQuery.data?.["lastUpdate"] === "" || caseQuery.data?.["lastUpdate"] === undefined || caseQuery.data?.["lastUpdate"] === null
					? null
					: dayjs(caseQuery.data?.["lastUpdate"]),
		};
		setLastUpdateMain(tempCommonData.lastUpdate);
		setCommonData(tempCommonData);
		const fieldIds = Object.keys(caseQuery.data || {}).filter((label) => !Object.keys(tempCommonData).includes(label));
		const caseData: { [id: string]: string } = {};
		fieldIds.forEach((id) => (caseData[id] = caseQuery.data?.[id] || ""));
		setCaseData(caseData);
		setIsArchived(caseQuery.data?.archived);
	}, [caseQuery.data]);

	const declareEdited = (id: string, status: boolean) => {
		if (status) {
			if (!editedCategories.includes(id)) {
				setEditedCategories([...editedCategories, id]);
			}
		} else {
			if (editedCategories.includes(id)) {
				const newArray = [...editedCategories];
				newArray.splice(editedCategories.indexOf(id), 1);
				setEditedCategories(newArray);
			}
		}
	};

	return (
		<Modal open={detailsModal !== null} onClose={() => (editedCategories.length > 0 ? setEditedDialog("exit") : onClose())} sx={{ color: "inherit" }}>
			<Box
				sx={{
					position: "absolute",
					top: "15px",
					maxHeight: "calc(100% - 30px)",
					left: "30px",
					right: "30px",
					boxShadow: 24,
					background: (theme) => theme.palette.background.paper,
					overflow: "auto",
					borderRadius: "5px",
				}}
			>
				<Box
					sx={{
						minWidth: "800px",
					}}
				>
					<AppBar position="sticky" sx={isArchived ? { backgroundColor: (theme) => theme.palette.grey[900] } : {}}>
						<Toolbar>
							<Box sx={{ flexGrow: 1 }}>
								<Typography sx={{ display: "inline-block", padding: "10px" }}>Client Name:</Typography>
								<Typography
									sx={{
										display: "inline-block",
										padding: "10px",
										fontWeight: "bold",
										border: "1px solid rgba(255, 255, 255, 0.23)",
										borderRadius: "4px",
									}}
								>
									{caseQuery.data?.["Client Name$"] || caseQuery.data?.["Client Name$_0"]}
								</Typography>
								<Typography sx={{ display: "inline-block", padding: "10px" }}>Case Number:</Typography>
								<Typography
									sx={{
										display: "inline-block",
										padding: "10px",
										fontWeight: "bold",
										border: "1px solid rgba(255, 255, 255, 0.23)",
										borderRadius: "4px",
									}}
								>
									{caseQuery.data?.caseNumber}
								</Typography>
							</Box>
							<Tabs
								value={tab}
								onChange={(_, value) => (editedCategories.length > 0 ? setEditedDialog(`tab${value as "0" | "1"}`) : setTab(value))}
							>
								<Tab label="Case Details"></Tab>
								<Tab label="Key Information"></Tab>
							</Tabs>
							<Tooltip title="Print Case Information">
								<IconButton sx={{ color: "inherit" }} onClick={() => setOpenPrintModal(true)}>
									<Print />
								</IconButton>
							</Tooltip>
							{isArchived ? (
								<Tooltip title="Unarchive">
									<IconButton
										onClick={() => {
											setPostStatus(QueryStatus.LOADING);
											postAction(
												{
													action: "undelete-case",
													id,
												},
												sessionState,
												setSessionState,
												() => {
													setPostStatus(QueryStatus.SUCCESS);
													caseQuery.refetch();
												},
												(e) => {
													console.error(e);
													setPostStatus(QueryStatus.FAILURE);
												}
											);
										}}
										sx={{ color: "inherit" }}
									>
										<Unarchive />
									</IconButton>
								</Tooltip>
							) : (
								<Tooltip title="Archive">
									<IconButton onClick={() => setDeleteModal(id)} sx={{ color: "inherit" }}>
										<Archive />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip title="Close Window">
								<IconButton onClick={() => (editedCategories.length > 0 ? setEditedDialog("exit") : onClose())} sx={{ color: "inherit" }}>
									<Close />
								</IconButton>
							</Tooltip>
						</Toolbar>
						{(postStatus === QueryStatus.LOADING || caseQuery.isFetching) && (
							<LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />
						)}
						{(postStatus === QueryStatus.FAILURE || caseQuery.isError) && (
							<Alert variant="filled" severity="error" onClose={() => setPostStatus(QueryStatus.IDLE)}>
								An error occurred, please try again.
							</Alert>
						)}
					</AppBar>
					{tab === 0 && (
						<Box sx={{ height: "900px" }}>
							<Box sx={{ m: 1, background: (theme) => theme.palette.grey[900], borderRadius: "8px", display: "flex" }}>
								<FormControl sx={{ m: 1, minWidth: 300 }}>
									<DatePicker
										views={["day", "month", "year"]}
										slotProps={{ textField: { size: "small" } }}
										label="Last Update"
										value={lastUpdateMain}
										onChange={(v) => {
											setLastUpdateMain(v);
										}}
									/>
								</FormControl>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Tooltip title="Revert Changes to Last Update Field">
										<IconButton
											size="small"
											sx={{ background: (theme) => theme.palette.primary.main, m: 1 }}
											disabled={compareDates(lastUpdateMain, commonData.lastUpdate)}
											onClick={() => {
												setLastUpdateMain(commonData.lastUpdate);
											}}
										>
											<Restore />
										</IconButton>
									</Tooltip>
									<Tooltip title="Save Changes to Last Update Field">
										<IconButton
											size="small"
											sx={{ background: (theme) => theme.palette.primary.main, m: 1 }}
											disabled={compareDates(lastUpdateMain, commonData.lastUpdate)}
											onClick={() => {
												setPostStatus(QueryStatus.LOADING);
												postAction(
													{
														action: "update-case",
														id: id,
														data: {
															...caseData,
															...commonData,
															archived: isArchived,
															_id: undefined,
															caseOpenDate: commonData.caseOpenDate?.toISOString(),
															lastUpdate: lastUpdateMain ? lastUpdateMain?.toISOString() : undefined,
														},
													},
													sessionState,
													setSessionState,
													() => {
														caseQuery.refetch();
														setPostStatus(QueryStatus.SUCCESS);
													},
													(e) => {
														console.log(e);
														setPostStatus(QueryStatus.FAILURE);
													}
												);
											}}
										>
											<Save />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
							<Divider />
							<PanelGroup direction="vertical">
								<Panel minSize={25}>
									<Box
										sx={{
											height: "100%",
											m: 2,
										}}
									>
										<DetailsGrid
											saveKeyPressed={saveKeyPressed}
											id={id}
											type={DetailsType.Note}
											sessionState={sessionState}
											setSessionState={setSessionState}
											setPostStatus={setPostStatus}
											declareEdited={declareEdited}
											defaultDirection="down"
										/>
									</Box>
								</Panel>
								<PanelResizeHandle style={{ border: "1px solid grey" }} />
								<Panel minSize={25}>
									<Box
										sx={{
											height: "100%",
											m: 2,
										}}
									>
										<DetailsGrid
											saveKeyPressed={saveKeyPressed}
											id={id}
											type={DetailsType.Task}
											sessionState={sessionState}
											setSessionState={setSessionState}
											setPostStatus={setPostStatus}
											declareEdited={declareEdited}
											defaultDirection="up"
										/>
									</Box>
								</Panel>
								<PanelResizeHandle style={{ border: "1px solid grey" }} />
								<Panel minSize={25}>
									<Box
										sx={{
											height: "100%",
											m: 2,
										}}
									>
										<DetailsGrid
											saveKeyPressed={saveKeyPressed}
											id={id}
											type={DetailsType.Deadline}
											sessionState={sessionState}
											setSessionState={setSessionState}
											setPostStatus={setPostStatus}
											declareEdited={declareEdited}
											defaultDirection="up"
										/>
									</Box>
								</Panel>
							</PanelGroup>
						</Box>
					)}
					{tab === 1 && (
						<Box sx={{ position: "relative" }}>
							{!caseQuery.isLoading && (
								<Box sx={{ m: 1 }}>
									<CaseFields
										saveKeyPressed={saveKeyPressed}
										declareEdited={declareEdited}
										settings={settings}
										commonData={commonData}
										caseData={caseData}
										onSubmit={async (newCommonData, newCaseData) => {
											setPostStatus(QueryStatus.LOADING);
											postAction(
												{
													action: "update-case",
													id: id,
													data: {
														...newCaseData,
														...newCommonData,
														archived: isArchived,
														_id: undefined,
														caseOpenDate: newCommonData.caseOpenDate?.toISOString(),
														lastUpdate: newCommonData.lastUpdate ? newCommonData.lastUpdate?.toISOString() : undefined,
													},
												},
												sessionState,
												setSessionState,
												() => {
													caseQuery.refetch();
													setEditedCategories([]);
													setPostStatus(QueryStatus.SUCCESS);
												},
												(e) => {
													console.log(e);
													setPostStatus(QueryStatus.FAILURE);
												}
											);
										}}
									/>
								</Box>
							)}
						</Box>
					)}
				</Box>
				<Dialog open={editedDialog !== null} onClose={() => setEditedDialog(null)}>
					<DialogTitle>{"You have unsaved changes, are you sure you want to delete them?"}</DialogTitle>
					<DialogActions>
						<Button onClick={() => setEditedDialog(null)}>Cancel</Button>
						<Button
							autoFocus
							variant="contained"
							onClick={() => {
								setEditedDialog(null);
								setEditedCategories([]);
								switch (editedDialog) {
									case "tab0":
										setTab(0);
										break;
									case "tab1":
										setTab(1);
										break;
									case "exit":
										onClose();
										break;
								}
							}}
						>
							Discard Changes
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={deleteModal !== null} onClose={() => setDeleteModal(null)}>
					<DialogTitle>{"Are you sure you want to archive this case?"}</DialogTitle>
					<DialogActions>
						<Button onClick={() => setDeleteModal(null)}>Cancel</Button>
						<Button
							variant="outlined"
							onClick={() => {
								setPostStatus(QueryStatus.LOADING);
								postAction(
									{
										action: "delete-case",
										id: deleteModal,
									},
									sessionState,
									setSessionState,
									() => {
										setPostStatus(QueryStatus.SUCCESS);
										setDeleteModal(null);
										caseQuery.refetch();
									},
									(e) => {
										setPostStatus(QueryStatus.FAILURE);
										console.log(e);
									}
								);
							}}
							autoFocus
						>
							Archive
						</Button>
					</DialogActions>
				</Dialog>
				{caseQuery.data && (
					<Modal open={openPrintModal} onClose={() => setOpenPrintModal(false)}>
						<Box
							sx={{
								position: "absolute",
								top: "30px",
								height: "calc(100% - 60px)",
								left: "60px",
								right: "60px",
								boxShadow: 24,
								background: (theme) => theme.palette.background.paper,
								overflow: "auto",
								borderRadius: "10px",
								p: 1,
							}}
						>
							<Tooltip title="Close Window">
								<IconButton
									sx={{ color: (theme) => theme.palette.common.white, float: "right", marginBottom: 1 }}
									onClick={() => setOpenPrintModal(false)}
								>
									<Close />
								</IconButton>
							</Tooltip>
							<DetailsPDF
								commonData={commonData}
								caseData={caseData}
								sessionState={sessionState}
								setSessionState={setSessionState}
								id={caseQuery.data["_id"]}
							/>
						</Box>
					</Modal>
				)}
			</Box>
		</Modal>
	);
}
