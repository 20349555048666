import { Close, Menu, Print } from "@mui/icons-material";
import {
	Alert,
	AppBar,
	Box,
	FormControl,
	FormHelperText,
	IconButton,
	LinearProgress,
	MenuItem,
	Modal,
	Select,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getData, getExemptions, getNotes, QueryStatus, SessionState, SessionStatus, SettingsObject } from "../utils";
import { Details } from "./Details";
import { ExemptionsGrid } from "./ExemptionsGrid";
import { ExemptionsPDF } from "./ExemptionsPDF";

interface ExemptionsProps {
	saveKeyPressed: boolean;
	settings: SettingsObject;
	isMobile: boolean;
	openDrawer: () => void;
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
}

export function Exemptions({
	settings: { attorneyList, staffList, statusTypes },
	saveKeyPressed,
	settings,
	isMobile,
	openDrawer,
	sessionState,
	setSessionState,
}: ExemptionsProps) {
	const [sortModel, setSortModel] = useState<{
		column: string;
		direction: boolean;
	}>({ column: "client", direction: true });

	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);
	const [detailsModal, setDetailsModal] = useState<string | null>(null);
	const [openPrintModal, setOpenPrintModal] = useState(false);

	const [attorney, setAttorney] = useState("*");
	const [assignedStaff, setAssignedStaff] = useState("*");
	const [status, setStatus] = useState("Active");
	const [nameSearch, setNameSearch] = useState("");

	const dataQuery = useQuery({
		queryKey: ["exemptions-data", attorney, assignedStaff, status],
		queryFn: () =>
			getData(
				{
					attorney: attorney === "*" ? undefined : attorney,
					staff: assignedStaff === "*" ? undefined : assignedStaff,
					status: status === "*" ? undefined : status,
					caseType: "Exemptions",
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	const dataIds = (dataQuery.data || []).map((datum: { [x: string]: any }) => datum["_id"]).sort();

	const detailsQuery = useQuery({
		queryKey: ["notes", JSON.stringify(dataIds)],
		queryFn: () =>
			getNotes(
				{
					archived: false,
					caseIds: dataIds,
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	const exemptionsQuery = useQuery({
		queryKey: ["exemptions", JSON.stringify(dataIds)],
		queryFn: () =>
			getExemptions(
				{
					archived: false,
					caseIds: dataIds,
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			dataQuery.refetch();
		}
	}, [sessionState]);

	const filteredData = (dataQuery.data || [])
		.filter((datum: { [id: string]: any }) => (datum["Client Name$"] || datum["Client Name$_0"] || "").toLowerCase().includes(nameSearch.toLowerCase()))
		.sort((a: { [x: string]: any }, b: { [x: string]: any }) => {
			switch (sortModel.column) {
				case "date":
					return (sortModel.direction ? -1 : 1) * dayjs(b["caseOpenDate"]).diff(dayjs(a["caseOpenDate"]));
				case "attorney":
					return (sortModel.direction ? -1 : 1) * b["attorney"].localeCompare(a["attorney"]);
				case "staff":
					return (sortModel.direction ? -1 : 1) * b["staff"].localeCompare(a["staff"]);
				case "status":
					return (
						(sortModel.direction ? -1 : 1) *
						((exemptionsQuery.data || {})[b["_id"]]?.status || settings.exemptionStatusTypes[0]).localeCompare(
							(exemptionsQuery.data || {})[a["_id"]]?.status || settings.exemptionStatusTypes[0]
						)
					);
				case "client":
				default:
					return (sortModel.direction ? -1 : 1) * (b["Client Name$"] || b["Client Name$_0"]).localeCompare(a["Client Name$"] || a["Client Name$_0"]);
			}
		});

	const isLoading = dataQuery.isFetching || detailsQuery.isFetching || exemptionsQuery.isFetching;

	return (
		<>
			<AppBar sx={isMobile ? {} : { left: "200px", width: "calc(100% - 200px)" }}>
				<Toolbar>
					{isMobile && (
						<Tooltip title="Open Menu">
							<IconButton sx={{ color: "inherit", marginRight: 1 }} onClick={openDrawer}>
								<Menu />
							</IconButton>
						</Tooltip>
					)}
					<Typography variant="h6" color="inherit" component="div" sx={{ flex: 1 }}>
						Exemptions
					</Typography>
					<Tooltip title="Print">
						<IconButton sx={{ color: "inherit" }} onClick={() => setOpenPrintModal(true)}>
							<Print />
						</IconButton>
					</Tooltip>
				</Toolbar>
				{postStatus === QueryStatus.LOADING && <LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />}
				{(dataQuery.isError || detailsQuery.isError || exemptionsQuery.isError) && (
					<Alert variant="filled" severity="error">
						An error occurred, try refreshing the page.
					</Alert>
				)}
			</AppBar>
			<Box sx={{ top: "64px", position: "relative", width: "100%" }}>
				<Box>
					<FormControl sx={{ m: 1, minWidth: 480 }}>
						<TextField value={nameSearch} onChange={(event) => setNameSearch(event.target.value)} />
						<FormHelperText>Search Client Name</FormHelperText>
					</FormControl>
					<FormControl sx={{ m: 1, minWidth: 200 }}>
						<Select value={attorney} onChange={(event) => setAttorney(event.target.value)}>
							<MenuItem
								value="*"
								style={{
									fontStyle: "italic",
								}}
							>
								All
							</MenuItem>
							{attorneyList.map((v) => (
								<MenuItem key={v} value={v}>
									{v}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Attorney</FormHelperText>
					</FormControl>
					<FormControl sx={{ m: 1, minWidth: 200 }}>
						<Select value={assignedStaff} onChange={(event) => setAssignedStaff(event.target.value)}>
							<MenuItem
								value="*"
								style={{
									fontStyle: "italic",
								}}
							>
								All
							</MenuItem>
							{staffList.map((v) => (
								<MenuItem key={v} value={v}>
									{v}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Assigned Staff</FormHelperText>
					</FormControl>
					<FormControl sx={{ m: 1, minWidth: 200 }}>
						<Select value={status} onChange={(event) => setStatus(event.target.value)}>
							<MenuItem
								value="*"
								style={{
									fontStyle: "italic",
								}}
							>
								All
							</MenuItem>
							{statusTypes.map((v) => (
								<MenuItem key={v} value={v}>
									{v}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Active</FormHelperText>
					</FormControl>
				</Box>
				<div id="datagrid-start" />
				<Box sx={{ position: "relative", border: (theme) => `1px solid ${theme.palette.grey[800]}`, borderRadius: "4px", m: 1 }}>
					{isLoading && <LinearProgress sx={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 99 }} />}
					<ExemptionsGrid
						nameSearch={nameSearch}
						sortModel={sortModel}
						exemptionsQueryData={exemptionsQuery.data || {}}
						detailsQueryData={detailsQuery.data || {}}
						filteredData={filteredData}
						settings={settings}
						setPostStatus={setPostStatus}
						sessionState={sessionState}
						setSessionState={setSessionState}
						setDetailsModal={setDetailsModal}
						refetchExemptions={() => exemptionsQuery.refetch()}
						setSortModel={setSortModel}
					/>
				</Box>
			</Box>
			<Details
				saveKeyPressed={saveKeyPressed}
				detailsModal={detailsModal}
				settings={settings}
				id={detailsModal || ""}
				onClose={() => {
					setDetailsModal(null);
					dataQuery.refetch();
				}}
				sessionState={sessionState}
				setSessionState={setSessionState}
			/>
			<Modal open={openPrintModal} onClose={() => setOpenPrintModal(false)}>
				<Box
					sx={{
						position: "absolute",
						top: "30px",
						height: "calc(100% - 60px)",
						left: "60px",
						right: "60px",
						boxShadow: 24,
						background: (theme) => theme.palette.background.paper,
						overflow: "auto",
						borderRadius: "10px",
						p: 1,
					}}
				>
					<Tooltip title="Close Window">
						<IconButton
							sx={{ color: (theme) => theme.palette.common.white, float: "right", marginBottom: 1 }}
							onClick={() => setOpenPrintModal(false)}
						>
							<Close />
						</IconButton>
					</Tooltip>
					<ExemptionsPDF
						caseData={filteredData}
						notesData={detailsQuery.data || {}}
						exemptionsData={exemptionsQuery.data || {}}
						settings={settings}
					/>
				</Box>
			</Modal>
		</>
	);
}
