import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Add, Check, Close, Delete, DragIndicator, Error, Restore } from "@mui/icons-material";
import { Alert, AppBar, Box, Button, IconButton, LinearProgress, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { QueryStatus, SessionState, SettingsObject, postAction } from "../utils";
import Editor from "react-simple-code-editor";
import Prism, { highlight } from "prismjs";
import "prismjs/components/prism-json.min";
import "prismjs/themes/prism-solarizedlight.css";

interface SettingsTabProps {
	onClose: () => void;
	settings: SettingsObject;
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
	refetchSettings: () => void;
	saveKeyPressed: boolean;
}

interface DraggableListProps {
	listId: string;
	list: { value: string; id: string }[];
	setList: (arg0: { value: string; id: string }[]) => void;
}

function areArraysEqual(a: string[], b: string[]) {
	if (a === undefined && b !== undefined) {
		return false;
	}
	if (a !== undefined && b === undefined) {
		return false;
	}
	if (a === undefined && b === undefined) {
		return true;
	}

	if (a.length !== b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return false;
		}
	}

	return true;
}

function DraggableList({ listId, list, setList }: DraggableListProps) {
	return (
		<Box
			sx={{
				display: "table",
				border: (theme) => `1px solid ${theme.palette.grey[800]}`,
				borderRadius: "4px",
			}}
		>
			<DragDropContext
				onDragEnd={({ destination, source, draggableId }) => {
					if (!destination) {
						return;
					}
					if (destination.droppableId === source.droppableId && destination.index === source.index) {
						return;
					}
					const newList = Array.from(list);
					const value = list.find(({ id }) => id === draggableId)?.value as string;
					newList.splice(source.index, 1);
					newList.splice(destination.index, 0, { value, id: draggableId });

					setList(newList);
				}}
			>
				<Droppable key={listId} droppableId={listId}>
					{(provided) => (
						<Box sx={{ display: "table" }} ref={provided.innerRef} {...provided.droppableProps}>
							{list.map(({ value, id }, index) => (
								<Draggable key={id} draggableId={id} index={index}>
									{(provided) => (
										<div {...provided.draggableProps} ref={provided.innerRef}>
											<Box
												sx={{
													margin: 0,
													paddingTop: 1,
													paddingBottom: 1,
													backgroundColor: (theme) => theme.palette.grey[900],
													display: "flex",
													alignItems: "center",
													border: (theme) => `0.5px solid ${theme.palette.grey[800]}`,
												}}
											>
												<div {...provided.dragHandleProps}>
													<DragIndicator sx={{ color: (theme) => theme.palette.grey[800], fontSize: "32px" }} />
												</div>
												<TextField
													sx={{ width: "300px" }}
													value={value}
													onChange={(e) => {
														const newList = Array.from(list);
														newList[index] = { value: e.target.value, id };
														setList(newList);
													}}
												/>
												<Tooltip title="Delete">
													<IconButton
														size="small"
														sx={{ margin: 1, float: "right" }}
														onClick={() => {
															const newList = Array.from(list);
															newList.splice(index, 1);
															setList(newList);
														}}
													>
														<Delete />
													</IconButton>
												</Tooltip>
											</Box>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>
			<Tooltip title="Add New Field">
				<IconButton
					sx={{
						float: "right",
						m: 1,
						backgroundColor: (theme) => theme.palette.primary.main,
					}}
					onClick={() =>
						setList([
							...list,
							{
								id: uuid(),
								value: "",
							},
						])
					}
				>
					<Add />
				</IconButton>
			</Tooltip>
		</Box>
	);
}

export function SettingsTab({ settings, sessionState, setSessionState, refetchSettings, onClose, saveKeyPressed }: SettingsTabProps) {
	const [attorneyList, setAttorneyList] = useState<{ value: string; id: string }[]>([]);
	const [accountTypes, setAccountTypes] = useState<{ value: string; id: string }[]>([]);
	const [staffList, setStaffList] = useState<{ value: string; id: string }[]>([]);
	const [caseTypes, setCaseTypes] = useState<{ value: string; id: string }[]>([]);
	const [exemptionApplicationTypes, setExemptionApplicationTypes] = useState<{ value: string; id: string }[]>([]);
	const [exemptionStatusTypes, setExemptionStatusTypes] = useState<{ value: string; id: string }[]>([]);

	const [advancedSettings, setAdvancedSettings] = useState<string>("");

	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);

	const resetSettings = () => {
		setAttorneyList((settings.attorneyList || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setAccountTypes((settings.accountTypes || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setStaffList((settings.staffList || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setCaseTypes((settings.caseTypes || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setExemptionApplicationTypes((settings.exemptionApplicationTypes || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setExemptionStatusTypes((settings.exemptionStatusTypes || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setAdvancedSettings(JSON.stringify(settings.layoutByCaseType, null, 2));
	};

	useEffect(resetSettings, [settings]);

	let isJsonEdited = false;
	let jsonError = false;
	try {
		isJsonEdited = JSON.stringify(JSON.parse(advancedSettings)) !== JSON.stringify(settings.layoutByCaseType);
	} catch (e) {
		jsonError = true;
	}

	const isEdited =
		!areArraysEqual(
			attorneyList.map(({ value }) => value),
			settings.attorneyList
		) ||
		!areArraysEqual(
			accountTypes.map(({ value }) => value),
			settings.accountTypes
		) ||
		!areArraysEqual(
			staffList.map(({ value }) => value),
			settings.staffList
		) ||
		!areArraysEqual(
			caseTypes.map(({ value }) => value),
			settings.caseTypes
		) ||
		!areArraysEqual(
			exemptionApplicationTypes.map(({ value }) => value),
			settings.exemptionApplicationTypes
		) ||
		!areArraysEqual(
			exemptionStatusTypes.map(({ value }) => value),
			settings.exemptionStatusTypes
		) ||
		isJsonEdited;

	const updateAction = () =>
		postAction(
			{
				action: "set-settings",
				id: settings["_id"],
				data: {
					...settings,
					_id: undefined,
					attorneyList: attorneyList.map(({ value }) => value),
					accountTypes: accountTypes.map(({ value }) => value),
					staffList: staffList.map(({ value }) => value),
					caseTypes: caseTypes.map(({ value }) => value),
					exemptionApplicationTypes: exemptionApplicationTypes.map(({ value }) => value),
					exemptionStatusTypes: exemptionStatusTypes.map(({ value }) => value),
					layoutByCaseType: JSON.parse(advancedSettings),
				},
			},
			sessionState,
			setSessionState,
			() => {
				setPostStatus(QueryStatus.SUCCESS);
				refetchSettings();
			},
			(e) => {
				console.log(e);
				setPostStatus(QueryStatus.FAILURE);
			}
		);

	useEffect(() => {
		if (saveKeyPressed === true) {
			if (!isEdited || jsonError) {
				return;
			}
			updateAction();
		}
	}, [saveKeyPressed]);

	return (
		<>
			<AppBar position="sticky">
				<Toolbar>
					<Typography variant="h6" color="inherit" component="div" sx={{ flex: 1 }}>
						Settings
					</Typography>
					<Tooltip title="Undo Changes">
						<IconButton sx={{ m: 1 }} disabled={!isEdited && !jsonError} onClick={resetSettings}>
							<Restore />
						</IconButton>
					</Tooltip>
					<Button variant="contained" disabled={!isEdited || jsonError} onClick={updateAction}>
						Update Settings
					</Button>
					<Tooltip title="Close Window">
						<IconButton onClick={() => onClose()} sx={{ color: "inherit" }}>
							<Close />
						</IconButton>
					</Tooltip>
				</Toolbar>
				{postStatus === QueryStatus.LOADING && <LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />}
				{postStatus === QueryStatus.FAILURE && (
					<Alert variant="filled" severity="error" onClose={() => setPostStatus(QueryStatus.IDLE)}>
						An error occurred, please try again.
					</Alert>
				)}
			</AppBar>
			<Box sx={{ position: "relative", width: "100%" }}>
				<Box sx={{ display: "flex", flexWrap: "wrap" }}>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Attorney List</Typography>
						<DraggableList listId="attorneyList" list={attorneyList} setList={setAttorneyList} />
					</Box>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Staff List</Typography>
						<DraggableList listId="staffList" list={staffList} setList={setStaffList} />
					</Box>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Account Types</Typography>
						<DraggableList listId="accountTypes" list={accountTypes} setList={setAccountTypes} />
					</Box>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Case Types</Typography>
						<DraggableList listId="caseTypes" list={caseTypes} setList={setCaseTypes} />
					</Box>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Exemption Application Types</Typography>
						<DraggableList listId="exemptionApplicationTypes" list={exemptionApplicationTypes} setList={setExemptionApplicationTypes} />
					</Box>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Exemption Status Types</Typography>
						<DraggableList listId="exemptionStatusTypes" list={exemptionStatusTypes} setList={setExemptionStatusTypes} />
					</Box>
				</Box>
				<Box
					sx={{
						margin: 2,
					}}
				>
					<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Advanced Settings</Typography>
					{jsonError ? (
						<Error fontSize="large" sx={{ float: "right", color: (theme) => theme.palette.error.main }} />
					) : (
						<Check fontSize="large" sx={{ float: "right", color: (theme) => theme.palette.info.main }} />
					)}
					<Box
						sx={{
							border: (theme) => `1px solid ${theme.palette.grey[800]}`,
							borderRadius: "4px",
							background: "#fff",
							maxHeight: "500px",
							overflow: "auto",
						}}
					>
						<Editor
							highlight={(code) => highlight(code, Prism.languages.json, "json")}
							onValueChange={setAdvancedSettings}
							value={advancedSettings}
							padding={"10px"}
							style={{
								fontFamily: '"Fira code", "Fira Mono", monospace',
								fontSize: 12,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
}
