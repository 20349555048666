import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Detail, Exemption, postAction, QueryStatus, SessionState, SettingsObject } from "../utils";

interface ExemptionsGridProps {
	nameSearch: string;
	sortModel: {
		column: string;
		direction: boolean;
	};
	exemptionsQueryData: { [x: string]: Exemption };
	detailsQueryData: { [x: string]: Detail[] };
	filteredData: { [x: string]: any }[];
	settings: SettingsObject;
	setPostStatus: (arg0: QueryStatus) => void;
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
	setDetailsModal: (arg0: string) => void;
	refetchExemptions: () => void;
	setSortModel: (arg0: { column: string; direction: boolean }) => void;
}

export function ExemptionsGrid({
	nameSearch,
	sortModel,
	filteredData,
	exemptionsQueryData,
	detailsQueryData,
	settings,
	setPostStatus,
	sessionState,
	setSessionState,
	setDetailsModal,
	refetchExemptions,
	setSortModel,
}: ExemptionsGridProps) {
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);

	const maxPages = Math.ceil(filteredData.length / pageSize);

	useEffect(() => {
		if (maxPages <= page) {
			setPage(Math.max(0, maxPages - 1));
		}
	}, [pageSize, nameSearch]);

	const currentCases = filteredData.filter((_: any, i: number) => i >= page * pageSize && i < (page + 1) * pageSize);

	return (
		<>
			<TableContainer
				sx={{
					maxHeight: `calc(100vh - ${document.getElementById("datagrid-start")?.getBoundingClientRect().top}px - 84px)`,
				}}
			>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sortDirection={sortModel.column === "date" ? (sortModel.direction ? "desc" : "asc") : false}>
								<TableSortLabel
									active={sortModel.column === "date"}
									direction={sortModel.direction ? "desc" : "asc"}
									onClick={() => setSortModel({ column: "date", direction: sortModel.direction ? !sortModel.direction : true })}
								>
									Case Opened
								</TableSortLabel>
							</TableCell>
							<TableCell sortDirection={sortModel.column === "client" ? (sortModel.direction ? "desc" : "asc") : false}>
								<TableSortLabel
									active={sortModel.column === "client"}
									direction={sortModel.direction ? "desc" : "asc"}
									onClick={() => setSortModel({ column: "client", direction: sortModel.direction ? !sortModel.direction : true })}
								>
									Client Name
								</TableSortLabel>
							</TableCell>
							<TableCell sortDirection={sortModel.column === "attorney" ? (sortModel.direction ? "desc" : "asc") : false}>
								<TableSortLabel
									active={sortModel.column === "attorney"}
									direction={sortModel.direction ? "desc" : "asc"}
									onClick={() => setSortModel({ column: "attorney", direction: sortModel.direction ? !sortModel.direction : true })}
								>
									Attorney
								</TableSortLabel>
							</TableCell>
							<TableCell sortDirection={sortModel.column === "staff" ? (sortModel.direction ? "desc" : "asc") : false}>
								<TableSortLabel
									active={sortModel.column === "staff"}
									direction={sortModel.direction ? "desc" : "asc"}
									onClick={() => setSortModel({ column: "staff", direction: sortModel.direction ? !sortModel.direction : true })}
								>
									Staff
								</TableSortLabel>
							</TableCell>
							<TableCell>Application</TableCell>
							<TableCell>Submitted</TableCell>
							<TableCell sortDirection={sortModel.column === "status" ? (sortModel.direction ? "desc" : "asc") : false}>
								<TableSortLabel
									active={sortModel.column === "status"}
									direction={sortModel.direction ? "desc" : "asc"}
									onClick={() => setSortModel({ column: "status", direction: sortModel.direction ? !sortModel.direction : true })}
								>
									Status
								</TableSortLabel>
							</TableCell>
							<TableCell>Notes</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{currentCases.map((datum: { [x: string]: any }) => {
							const {
								application = settings.exemptionApplicationTypes[0],
								status = settings.exemptionStatusTypes[0],
								submitted,
							} = exemptionsQueryData[datum["_id"]] || {};
							return (
								<TableRow key={datum["_id"]} sx={{ ":hover": { background: "#212121" } }}>
									<TableCell sx={{ padding: "10px" }}>{dayjs(datum["caseOpenDate"]).format("MM/DD/YYYY hh:mm A")}</TableCell>
									<TableCell
										sx={{
											padding: "10px",
											cursor: "pointer",
											":hover": { textDecoration: "underline", textDecorationStyle: "dotted" },
										}}
										onClick={() => setDetailsModal(datum["_id"])}
									>
										{datum["Client Name$"] || datum["Client Name$_0"]}
									</TableCell>
									<TableCell sx={{ padding: "10px" }}>{datum["attorney"]}</TableCell>
									<TableCell sx={{ padding: "10px" }}>{datum["staff"]}</TableCell>
									<TableCell sx={{ padding: "10px", minWidth: "160px" }}>
										<Select
											fullWidth
											value={application}
											size="small"
											onChange={(event) => {
												setPostStatus(QueryStatus.LOADING);
												postAction(
													{
														action: "edit-exemption",
														caseId: datum["_id"],
														data: {
															application: event.target.value,
															status,
															submitted,
														},
													},
													sessionState,
													setSessionState,
													() => {
														setPostStatus(QueryStatus.SUCCESS);
														refetchExemptions();
													},
													(e) => {
														console.error(e);
														setPostStatus(QueryStatus.FAILURE);
													}
												);
											}}
										>
											{settings.exemptionApplicationTypes.map((type) => (
												<MenuItem key={type} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</TableCell>
									<TableCell sx={{ padding: "5px", minWidth: "160px" }}>
										<DatePicker
											value={submitted ? dayjs(submitted) : null}
											slotProps={{ textField: { size: "small", fullWidth: true } }}
											onChange={(date) => {
												setPostStatus(QueryStatus.LOADING);
												postAction(
													{
														action: "edit-exemption",
														caseId: datum["_id"],
														data: {
															application,
															status,
															submitted: date,
														},
													},
													sessionState,
													setSessionState,
													() => {
														setPostStatus(QueryStatus.SUCCESS);
														refetchExemptions();
													},
													(e) => {
														console.error(e);
														setPostStatus(QueryStatus.FAILURE);
													}
												);
											}}
										/>
									</TableCell>
									<TableCell sx={{ padding: "5px", minWidth: "160px" }}>
										<Select
											fullWidth
											value={status}
											size="small"
											onChange={(event) => {
												setPostStatus(QueryStatus.LOADING);
												postAction(
													{
														action: "edit-exemption",
														caseId: datum["_id"],
														data: {
															application,
															status: event.target.value,
															submitted,
														},
													},
													sessionState,
													setSessionState,
													() => {
														setPostStatus(QueryStatus.SUCCESS);
														refetchExemptions();
													},
													(e) => {
														console.error(e);
														setPostStatus(QueryStatus.FAILURE);
													}
												);
											}}
										>
											{settings.exemptionStatusTypes.map((type) => (
												<MenuItem key={type} value={type}>
													{type}
												</MenuItem>
											))}
										</Select>
									</TableCell>
									<TableCell sx={{ padding: "5px" }}>
										{(detailsQueryData[datum["_id"]] || []).length === 0 ? (
											<Box sx={{ display: "flex", justifyContent: "center" }}>
												<Typography sx={{ color: (theme) => theme.palette.grey[600], fontSize: "12px" }}>No Notes</Typography>
											</Box>
										) : (
											<Box
												sx={{
													maxHeight: "180px",
													minWidth: "360px",
													overflow: "scroll",
													border: (theme) => `1px solid ${theme.palette.grey[800]}`,
													borderRadius: "4px",
												}}
											>
												{(detailsQueryData[datum["_id"]] || []).map(({ _id, description, date }) => (
													<Box
														key={`${datum["_id"]}-${_id}`}
														sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[800]}`, padding: "10px" }}
													>
														<Box
															sx={{
																color: (theme) => theme.palette.grey[600],
																fontStyle: "italic",
																fontSize: "11px",
															}}
														>
															{dayjs(date).format("MM/DD/YYYY hh:mm A")}
														</Box>
														<Box>{description}</Box>
													</Box>
												))}
											</Box>
										)}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<Box
				sx={{
					border: (theme) => `1px solid ${theme.palette.grey[800]}`,
					height: "45px",
					borderRadius: "4px",
					position: "absolute",
					bottom: "-55px",
					width: "calc(100vw - 234px)",
					display: "flex",
					alignItems: "center",
					justifyContent: "right",
				}}
			>
				<IconButton sx={{ float: "right", m: 1 }} size="small" onClick={() => page > 0 && setPage(page - 1)}>
					<ArrowBackIos />
				</IconButton>
				<Typography sx={{ float: "right", color: (theme) => theme.palette.common.white }}>
					{page + 1}/{maxPages}
				</Typography>
				<IconButton size="small" sx={{ float: "right", m: 1 }} onClick={() => page < maxPages - 1 && setPage(page + 1)}>
					<ArrowForwardIos />
				</IconButton>
				<Select sx={{ m: 1, p: 0, fontSize: "0.75rem" }} size="small" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
					<MenuItem value={10}>10</MenuItem>
					<MenuItem value={20}>20</MenuItem>
					<MenuItem value={50}>50</MenuItem>
					<MenuItem value={100}>100</MenuItem>
				</Select>
			</Box>
		</>
	);
}
