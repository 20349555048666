import { BorderAll, Close, Menu, Print, Search, Settings } from "@mui/icons-material";
import {
	Alert,
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	IconButton,
	Modal,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridSortItem } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { SessionState, SessionStatus, SettingsObject, getData, getDeadlines, postAction, sortCaseListData } from "../utils";
import { Details } from "./Details";
import { GalleryPDF } from "./GalleryPDF";
import { SearchForm } from "./SearchForm";
import { SettingsTab } from "./SettingsTab";

interface GalleryProps {
	isMobile: boolean;
	settings: SettingsObject;
	sessionState: SessionState;
	saveKeyPressed: boolean;
	setSessionState: (arg0: SessionState) => void;
	refetchSettings: () => void;
	openDrawer: () => void;
}

export function Gallery({ isMobile, settings, sessionState, saveKeyPressed, setSessionState, refetchSettings, openDrawer }: GalleryProps) {
	const [openSettingsModal, setOpenSettingsModal] = useState(false);
	const [openSearchModal, setOpenSearchModal] = useState(false);
	const [openPrintModal, setOpenPrintModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState<string | null>(null);

	const [caseNumber, setCaseNumber] = useState("");
	const [searchError, setSearchError] = useState(false);

	const [attorney, setAttorney] = useState("*");
	const [caseType, setCaseType] = useState("*");
	const [assignedStaff, setAssignedStaff] = useState("*");
	const [accountType, setAccountType] = useState("*");
	const [drmOrOther, setDrmOrOther] = useState("*");
	const [status, setStatus] = useState("Active");

	const [nameSearch, setNameSearch] = useState("");
	const [opposingSearch, setOpposingSearch] = useState("");

	const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: "clientName", sort: "asc" }]);

	const [csvFile, setCsvFile] = useState<Blob | undefined>(undefined);
	const [csvFileDate, setCsvFileDate] = useState<string | undefined>(undefined);

	const dataQuery = useQuery({
		queryKey: ["data", attorney, caseType, assignedStaff, accountType, drmOrOther, status],
		queryFn: () =>
			getData(
				{
					archived: false,
					attorney: attorney === "*" ? undefined : attorney,
					caseType: caseType === "*" ? undefined : caseType,
					staff: assignedStaff === "*" ? undefined : assignedStaff,
					accountType: accountType === "*" ? undefined : accountType,
					DRMOther: drmOrOther === "*" ? undefined : drmOrOther,
					status: status === "*" ? undefined : status,
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	const dataIds = (dataQuery.data || []).map((datum: { [x: string]: any }) => datum["_id"]);

	const detailsQuery = useQuery({
		queryKey: ["details", JSON.stringify(dataIds)],
		queryFn: () =>
			getDeadlines(
				{
					archived: false,
					caseIds: dataIds,
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			dataQuery.refetch();
		}
	}, [sessionState]);

	useEffect(() => {
		const data = (dataQuery.data || []).filter(
			(datum: { [id: string]: any }) =>
				(datum["Client Name$"] || datum["Client Name$_0"] || "").toLowerCase().includes(nameSearch.toLowerCase()) &&
				(datum["Opposing Counsel$"] || "").toLowerCase().includes(opposingSearch.toLowerCase())
		);
		const deadlines = detailsQuery.data || {};
		const text = sortCaseListData(sortModel, data, deadlines)
			.map((datum: { [id: string]: any }) =>
				[
					dayjs(datum["caseOpenDate"]).format("MM/DD/YYYY hh:mm A"),
					datum["Client Name$"] || datum["Client Name$_0"] || "",
					datum["attorney"] || "",
					datum["status"] || "",
					datum["caseType"] || "",
					datum["staff"] || "",
					deadlines[datum["_id"]] === undefined ? "No Upcoming Deadlines" : dayjs(deadlines[datum["_id"]]).format("MM/DD/YYYY hh:mm A"),
				]
					.map((v) => (v.includes(",") ? `"${v}"` : v))
					.join(",")
			)
			.join("\n");
		const file = new Blob([`Case Opened,Client Name,Attorney,Status,Case Type,Staff Assigned,Upcoming Deadline\n${text}`], { type: "text/plain" });
		setCsvFile(file);
		setCsvFileDate(dayjs(Date.now()).format("MM-DD-YYYY hh:mm A"));
	}, [sortModel, dataQuery.data, detailsQuery.data]);

	return (
		<>
			<AppBar sx={isMobile ? {} : { left: "200px", width: "calc(100% - 200px)" }}>
				<Toolbar>
					{isMobile && (
						<Tooltip title="Open Menu">
							<IconButton sx={{ color: "inherit", marginRight: 1 }} onClick={openDrawer}>
								<Menu />
							</IconButton>
						</Tooltip>
					)}
					<Typography variant="h6" color="inherit" component="div" sx={{ flex: 1 }}>
						Gallery
					</Typography>
					<Tooltip title="Search by Case Number">
						<IconButton sx={{ color: "inherit" }} onClick={() => setOpenSearchModal(true)}>
							<Search />
						</IconButton>
					</Tooltip>
					<Tooltip title="Edit Settings">
						<IconButton sx={{ color: "inherit" }} onClick={() => setOpenSettingsModal(true)}>
							<Settings />
						</IconButton>
					</Tooltip>
					<Tooltip title="Print">
						<IconButton sx={{ color: "inherit" }} onClick={() => setOpenPrintModal(true)}>
							<Print />
						</IconButton>
					</Tooltip>
					<Tooltip title="Export to CSV">
						<IconButton
							download={`CASE LIST (${csvFileDate}).csv`}
							target="_blank"
							rel="noreferrer"
							disabled={csvFile === undefined}
							sx={{ color: "inherit" }}
							href={csvFile !== undefined ? URL.createObjectURL(csvFile) : ""}
						>
							<BorderAll />
						</IconButton>
					</Tooltip>
				</Toolbar>
				{(dataQuery.isError || detailsQuery.isError) && (
					<Alert variant="filled" severity="error">
						An error occurred, try refreshing the page.
					</Alert>
				)}
			</AppBar>
			<Box sx={{ top: "64px", position: "relative", width: "100%" }}>
				<SearchForm
					settings={settings}
					data={(dataQuery.data || []).filter(
						(datum: { [id: string]: any }) =>
							(datum["Client Name$"] || datum["Client Name$_0"] || "").toLowerCase().includes(nameSearch.toLowerCase()) &&
							(datum["Opposing Counsel$"] || "").toLowerCase().includes(opposingSearch.toLowerCase())
					)}
					deadlines={detailsQuery.data || {}}
					isLoading={dataQuery.isFetching || detailsQuery.isFetching}
					setDetailsModal={setDetailsModal}
					attorney={attorney}
					setAttorney={setAttorney}
					caseType={caseType}
					setCaseType={setCaseType}
					assignedStaff={assignedStaff}
					setAssignedStaff={setAssignedStaff}
					accountType={accountType}
					setAccountType={setAccountType}
					drmOrOther={drmOrOther}
					setDrmOrOther={setDrmOrOther}
					status={status}
					setStatus={setStatus}
					nameSearch={nameSearch}
					setNameSearch={setNameSearch}
					opposingSearch={opposingSearch}
					setOpposingSearch={setOpposingSearch}
					setSortModel={setSortModel}
				/>
			</Box>
			<Modal open={openPrintModal} onClose={() => setOpenPrintModal(false)}>
				<Box
					sx={{
						position: "absolute",
						top: "30px",
						height: "calc(100% - 60px)",
						left: "60px",
						right: "60px",
						boxShadow: 24,
						background: (theme) => theme.palette.background.paper,
						overflow: "auto",
						borderRadius: "10px",
						p: 1,
					}}
				>
					<Tooltip title="Close Window">
						<IconButton
							sx={{ color: (theme) => theme.palette.common.white, float: "right", marginBottom: 1 }}
							onClick={() => setOpenPrintModal(false)}
						>
							<Close />
						</IconButton>
					</Tooltip>
					<GalleryPDF
						sortModel={sortModel}
						data={(dataQuery.data || []).filter(
							(datum: { [id: string]: any }) =>
								(datum["Client Name$"] || datum["Client Name$_0"] || "").toLowerCase().includes(nameSearch.toLowerCase()) &&
								(datum["Opposing Counsel$"] || "").toLowerCase().includes(opposingSearch.toLowerCase())
						)}
						deadlines={detailsQuery.data || {}}
					/>
				</Box>
			</Modal>
			<Modal open={openSettingsModal} onClose={() => setOpenSettingsModal(false)}>
				<Box
					sx={{
						position: "absolute",
						top: "15px",
						maxHeight: "calc(100% - 30px)",
						left: "30px",
						right: "30px",
						boxShadow: 24,
						background: (theme) => theme.palette.background.paper,
						overflow: "auto",
						borderRadius: "10px",
					}}
				>
					<SettingsTab
						saveKeyPressed={saveKeyPressed}
						settings={settings}
						onClose={() => {
							setOpenSettingsModal(false);
							dataQuery.refetch();
						}}
						sessionState={sessionState}
						setSessionState={setSessionState}
						refetchSettings={refetchSettings}
					/>
				</Box>
			</Modal>
			<Details
				saveKeyPressed={saveKeyPressed}
				detailsModal={detailsModal}
				settings={settings}
				id={detailsModal || ""}
				onClose={() => {
					setDetailsModal(null);
					dataQuery.refetch();
					detailsQuery.refetch();
				}}
				sessionState={sessionState}
				setSessionState={setSessionState}
			/>
			<Dialog open={openSearchModal} onClose={() => setOpenSearchModal(false)}>
				<DialogContent>
					<DialogContentText>Search by Case Number:</DialogContentText>
					<TextField
						sx={{ width: "300px" }}
						value={caseNumber}
						onChange={(e) => {
							setCaseNumber(e.target.value);
							setSearchError(false);
						}}
						error={searchError}
					/>
					{searchError && <DialogContentText color="error">Case number {caseNumber} not found</DialogContentText>}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenSearchModal(false)}>Cancel</Button>
					<Button
						variant="outlined"
						onClick={() => {
							postAction(
								{
									action: "get-case-id-by-number",
									caseNumber,
								},
								sessionState,
								setSessionState,
								(res) => {
									if (res.id === undefined) {
										setSearchError(true);
									} else {
										setDetailsModal(res.id);
										setOpenSearchModal(false);
									}
								},
								(e) => {
									console.log(e);
								}
							);
						}}
						autoFocus
					>
						Search
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
