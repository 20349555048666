import { Menu } from "@mui/icons-material";
import { Alert, AppBar, Box, Button, IconButton, LinearProgress, Toolbar, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { CommonData, QueryStatus, SessionState, SettingsObject, postAction } from "../utils";
import CaseFields from "./CaseFields";

interface AddCaseProps {
	isMobile: boolean;
	settings: SettingsObject;
	onClose: () => void;
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
	openDrawer: () => void;
}

export function AddCase({
	isMobile,
	openDrawer,
	settings,
	settings: { caseTypes, drmOtherTypes, statusTypes },
	onClose,
	sessionState,
	setSessionState,
}: AddCaseProps) {
	const [commonData, setCommonData] = useState<CommonData>({
		caseType: caseTypes[0],
		attorney: "",
		accountType: "",
		DRMOther: drmOtherTypes[0],
		staff: "",
		caseOpenDate: null,
		lastUpdate: null,
		status: statusTypes[0],
		caseNumber: "",
	});

	const [caseData, setCaseData] = useState<{ [id: string]: string }>({});

	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);

	return (
		<>
			<AppBar sx={isMobile ? {} : { left: "200px", width: "calc(100% - 200px)" }}>
				<Toolbar>
					{isMobile && (
						<Tooltip title="Menu">
							<IconButton sx={{ color: "inherit", marginRight: 1 }} onClick={openDrawer}>
								<Menu />
							</IconButton>
						</Tooltip>
					)}
					<Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
						Add Case
					</Typography>
					<Button
						variant="contained"
						sx={{ margin: 1 }}
						disabled={commonData.caseOpenDate === null}
						onClick={async () => {
							setPostStatus(QueryStatus.LOADING);
							postAction(
								{
									action: "add-case",
									data: {
										...caseData,
										...commonData,
										caseOpenDate: commonData.caseOpenDate?.toISOString(),
										lastUpdate: commonData.lastUpdate ? commonData.lastUpdate?.toISOString() : undefined,
									},
								},
								sessionState,
								setSessionState,
								() => {
									setPostStatus(QueryStatus.SUCCESS);
									onClose();
								},
								(e) => {
									console.log(e);
									setPostStatus(QueryStatus.FAILURE);
								}
							);
						}}
					>
						Submit
					</Button>
					{postStatus === QueryStatus.LOADING && <LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />}
				</Toolbar>
				{postStatus === QueryStatus.LOADING && <LinearProgress />}
				{postStatus === QueryStatus.FAILURE && (
					<Alert variant="filled" severity="error" onClose={() => setPostStatus(QueryStatus.IDLE)}>
						An error occurred, please try again.
					</Alert>
				)}
			</AppBar>
			<Box sx={{ top: "64px", position: "relative", width: "100%" }}>
				<CaseFields settings={settings} commonData={commonData} caseData={caseData} setCommonData={setCommonData} setCaseData={setCaseData} synced />
			</Box>
		</>
	);
}
