import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { Detail, Exemption, SettingsObject } from "../utils";

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		fontSize: "10px",
		paddingTop: "45px",
		paddingBottom: "45px",
	},
	titleContainer: {
		flexDirection: "row",
	},
	title: {
		fontSize: "14px",
		padding: "2px",
		width: "50%",
	},
	date: {
		padding: "2px",
		width: "50%",
		textAlign: "right",
		verticalAlign: "sub",
	},
	table: {
		marginTop: "8px",
	},
	headerRow: {
		flexDirection: "row",
		backgroundColor: "#666",
		color: "#fff",
	},
	row: {
		flexDirection: "row",
		borderBottom: "1px dotted black",
	},
	caseOpenedRow: {
		width: "12%",
		padding: "4px",
	},
	clientNameRow: {
		width: "20%",
		padding: "4px",
	},
	attorneyRow: {
		width: "20%",
		padding: "4px",
	},
	staffRow: {
		width: "20%",
		padding: "4px",
	},
	applicationRow: {
		width: "15%",
		padding: "4px",
	},
	submittedRow: {
		width: "12%",
		padding: "4px",
	},
	statusRow: {
		width: "16%",
		padding: "4px",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
});

interface ExemptionsPDFProps {
	caseData: { [id: string]: string }[];
	notesData: { [id: string]: Detail };
	exemptionsData: { [id: string]: Exemption };
	settings: SettingsObject;
}

const PDFDocument = ({ caseData, exemptionsData, settings: { exemptionApplicationTypes, exemptionStatusTypes } }: ExemptionsPDFProps) => {
	return (
		<Document>
			<Page size="LETTER" style={styles.page}>
				<View style={styles.section}>
					<View style={styles.titleContainer}>
						<Text style={styles.title}>Exemptions List</Text>
						<Text style={styles.date}>AS OF {dayjs(Date.now()).format("MM/DD/YYYY hh:mm A")}</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.headerRow}>
							<Text style={styles.caseOpenedRow}>Case Opened</Text>
							<Text style={styles.clientNameRow}>Client Name</Text>
							<Text style={styles.attorneyRow}>Attorney</Text>
							<Text style={styles.staffRow}>Staff Assigned</Text>
							<Text style={styles.applicationRow}>Application</Text>
							<Text style={styles.submittedRow}>Submitted</Text>
							<Text style={styles.statusRow}>Status</Text>
						</View>
						{caseData.map((datum: any) => {
							const {
								application = exemptionApplicationTypes[0],
								status = exemptionStatusTypes[0],
								submitted,
							} = exemptionsData[datum["_id"]] || {};
							return (
								<View style={styles.row} wrap={false}>
									<Text style={styles.caseOpenedRow}>{dayjs(datum["caseOpenDate"]).format("MM/DD/YYYY hh:mm A")}</Text>
									<Text style={styles.clientNameRow}>{datum["Client Name$"] || datum["Client Name$_0"]}</Text>
									<Text style={styles.attorneyRow}>{datum["attorney"]}</Text>
									<Text style={styles.staffRow}>{datum["staff"]}</Text>
									<Text style={styles.applicationRow}>{application}</Text>
									<Text style={styles.submittedRow}>{submitted ? dayjs(submitted).format("MM/DD/YYYY hh:mm A") : "N/A"}</Text>
									<Text style={styles.statusRow}>{status}</Text>
								</View>
							);
						})}
					</View>
				</View>
			</Page>
		</Document>
	);
};

export const ExemptionsPDF = (props: ExemptionsPDFProps) => (
	<PDFViewer
		style={{
			width: "100%",
			height: "100%",
		}}
	>
		<PDFDocument {...props} />
	</PDFViewer>
);
